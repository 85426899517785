var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "c-search",
            {
              attrs: { option: _vm.searchOption },
              on: { submit: _vm.getData },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        "native-type": "submit",
                        size: "small",
                        type: "primary"
                      }
                    },
                    [_vm._v(" 查询 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" 重置 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("c-table", {
        attrs: {
          columns: _vm.columns,
          data: _vm.list,
          border: "",
          size: "mini",
          stripe: ""
        },
        on: { "selection-change": _vm.handleSelection },
        scopedSlots: _vm._u([
          {
            key: "btns",
            fn: function(scope) {
              return [
                scope.row.status == 1
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "danger", underline: false },
                        on: {
                          click: function($event) {
                            return _vm.handleStart(scope.row)
                          }
                        }
                      },
                      [_vm._v(" 开始盘点 ")]
                    )
                  : _vm._e(),
                scope.row.status == 2
                  ? [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "success", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.handleDownLoad(scope.row)
                            }
                          }
                        },
                        [_vm._v(" 下载表格 ")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.handleUpload(scope.row)
                            }
                          }
                        },
                        [_vm._v(" 导入结果 ")]
                      )
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("c-pagination", {
        attrs: { slot: "footer", page: _vm.page, search: _vm.getData },
        slot: "footer"
      }),
      _c("CheckDialog", { ref: "CheckDialog", on: { refresh: _vm.getData } }),
      _c("DownloadFile", { ref: "DownloadFile" }),
      _c("CheckFiles", {
        ref: "CheckFiles",
        attrs: { fileTypes: ["xls", "xlsx"] },
        on: {
          error: function(err, a) {
            return _vm.handleError(a)
          },
          submit: _vm.onsubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }