/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
import { getType } from '@/utils/options';


export var searchOption = function searchOption(ctx) {return [
  {
    kname: 'shelfId',
    label: '盘点位置：',
    component: {
      name: 'selector',
      props: {
        type: 'shelfs',
        clearable: true } } }];};





export var columns = function columns(ctx) {return [
  {
    label: '编号',
    kname: 'id' },

  {
    label: '任务起止时间',
    kname: 'period' },

  {
    label: '盘点位置',
    kname: 'shelfNo' },

  {
    label: '操作',
    kname: 'btns' }];};